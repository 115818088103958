import React, { useContext, useState } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  brandColours,
  fontSize,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
} from '../styles';
import { Button } from './ui';
import { formatPrice, translateString, useVariantPrice } from '../utils';
import ProductOptionsV2 from './ProductOptionsV2';
import ProductQuantity from './ProductQuantity';
import { StoreContext } from '../context/StoreContext';
import DispatchNotice from './DispatchNotice';

const StyledProductFormV2 = styled.div`
  background-color: ${standardColours.white};
  padding: 16px;
  box-shadow: 0px 13px 84px 0px ${standardColours.transparentBlack(0.08)};

  ${maxBreakpointQuery.mlarge`
    margin-top: 24px;
  `}

  ${minBreakpointQuery.tsmall`
    padding: 24px;
  `}
`;

const StyledForm = styled.form`
  margin-top: 36px;
`;

const StyledButtonTotal = styled.div`
  margin-top: 24px;

  ${minBreakpointQuery.tsmall`
    margin-top: 34px;
  `}

  ${minBreakpointQuery.small`
    margin-top: 44px;
  `}
`;

const StyledTotal = styled.p`
  font-weight: ${fontWeights.bold};
  ${fontSize(24)}

  span {
    margin-left: 8px;
    color: ${standardColours.darkerGrey};
    ${fontSize(12)}
    font-weight: ${fontWeights.regular};
  }
`;

const StyledAddToCart = styled.div`
  display: flex;
  margin-top: 16px;
`;

const StyledAddButton = styled(Button)`
  margin-left: ${({ isMural }) => (isMural ? '0' : '15')}px;
  width: 100%;
  background-color: ${brandColours.primary};
  border-color: ${brandColours.primary};
  padding: 10px 22px;
  ${fontSize(12)}

  ${({ isMural, isCustomColour }) => {
    if (isMural || isCustomColour) {
      return css`
        margin: 20px 0;
      `;
    }
  }}

  ${minBreakpointQuery.tsmall`
    ${fontSize(14)}
  `}
`;

const StyledCalculatorButtonWrapper = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${standardColours.lightGrey};
`;

const StyledCalculatorButton = styled.button`
  background-color: ${brandColours.senary};
  padding: 12px;
  text-align: center;
  border: none;
  width: 100%;
  white-space: break-spaces;
  ${fontSize(12)}

  span {
    color: ${brandColours.primary};
    text-decoration: underline;
  }
`;

const ProductFormV2 = ({
  options,
  getVariant,
  variants,
  minOrderValues,
  cropWidth,
  cropHeight,
  selectedUnit,
  excessMessaging,
  selectedVariant,
  paperTypes,
  total,
  setTotal,
  setCropWidth,
  setCropHeight,
  setSelectedUnit,
  setInputFocus,
  toggleCroppingModeOpen,
  toggleRollCalculatorOpen,
  handleChange,
  isMural,
  locale,
}) => {
  const { addProductsToCheckout, currency, currencyLoaded } =
    useContext(StoreContext);

  const [quantity, setQuantity] = useState(1);
  const [isCustomColour, setIsCustomColour] = useState(false);

  const variantPrice = useVariantPrice(selectedVariant);

  const unitConversion = selectedUnit
    ? selectedUnit === 'cm'
      ? 10
      : 1
    : undefined;

  const changeQuantity = e => {
    const value = e.target.value;

    if (value === 'remove') {
      setQuantity(quantity > 1 ? quantity - 1 : 1);
    } else if (value === 'add') {
      setQuantity(quantity ? quantity + 1 : 1);
    } else {
      setQuantity(parseInt(e.target.value));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const lineItems = [
      {
        variantId: selectedVariant.storefrontId,
        quantity: quantity || 1,
      },
    ];

    await addProductsToCheckout(lineItems);

    setQuantity(1);
  };

  return (
    <StyledProductFormV2>
      <ProductOptionsV2
        options={options}
        getVariant={getVariant}
        variants={variants}
        variantPrice={variantPrice}
        selectedVariant={selectedVariant}
        handleChange={handleChange}
        isMural={isMural}
        isCustomColour={isCustomColour}
        selectedUnit={selectedUnit}
        minOrderValues={minOrderValues}
        paperTypes={paperTypes}
        cropWidth={cropWidth}
        cropHeight={cropHeight}
        setTotal={setTotal}
        setCropWidth={setCropWidth}
        setCropHeight={setCropHeight}
        setSelectedUnit={setSelectedUnit}
        setInputFocus={setInputFocus}
        setIsCustomColour={setIsCustomColour}
        toggleCroppingModeOpen={toggleCroppingModeOpen}
        excessMessaging={excessMessaging}
        locale={locale}
      />
      {isMural ? (
        <StyledButtonTotal>
          <StyledTotal>
            {variantPrice &&
              currencyLoaded &&
              unitConversion &&
              formatPrice({
                amount: total ? total : variantPrice.amount * unitConversion,
                currencyCode: variantPrice.currencyCode,
                locale: locale,
              })}
            <span>{translateString('product.taxNote', locale)}</span>
          </StyledTotal>
          <DispatchNotice total={total} currency={currency} locale={locale} />
          {isCustomColour ? (
            <StyledAddButton
              isCustomColour={true}
              isMural={isMural}
              onClick={() => navigate('/contact/')}
            >
              {translateString('product.contact', locale)}
            </StyledAddButton>
          ) : (
            <StyledAddButton
              isMural={isMural}
              onClick={() => toggleCroppingModeOpen()}
            >
              {locale === 'en'
                ? currency === 'USD'
                  ? 'Customize & Buy'
                  : 'Customise & Buy'
                : translateString('product.customiseBuy', locale)}
            </StyledAddButton>
          )}
        </StyledButtonTotal>
      ) : isCustomColour ? (
        <StyledAddButton
          isCustomColour={true}
          isMural={isMural}
          onClick={() => navigate('/contact/')}
        >
          {translateString('product.contact', locale)}
        </StyledAddButton>
      ) : (
        <StyledForm onSubmit={e => handleSubmit(e)}>
          <DispatchNotice
            total={total}
            currency={currency}
            quantity={quantity}
            variantPrice={variantPrice}
            locale={locale}
          />
          <StyledAddToCart>
            <ProductQuantity
              quantity={quantity}
              changeFunction={changeQuantity}
            />
            <StyledAddButton>
              {translateString('product.addToCart', locale)}
            </StyledAddButton>
          </StyledAddToCart>
        </StyledForm>
      )}
      {!isMural && (
        <StyledCalculatorButtonWrapper>
          <StyledCalculatorButton onClick={toggleRollCalculatorOpen}>
            {translateString('product.rollsQuestion', locale)}{' '}
            <span>{translateString('product.rollCalculator', locale)}</span>
          </StyledCalculatorButton>
        </StyledCalculatorButtonWrapper>
      )}
    </StyledProductFormV2>
  );
};

export default ProductFormV2;
