import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  brandColours,
  fontSize,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
  standardTransition,
  visuallyHidden,
  zIndexLayers,
} from '../styles';
import { Link, Svg } from './ui';
import {
  formatPrice,
  getColourHex,
  translateString,
  useVariantPrice as getVariantPrice,
} from '../utils';
import { StoreContext } from '../context/StoreContext';
import starIcon from '../images/star.inline.svg';
import infoIcon from '../images/info-icon.inline.svg';
import brushIcon from '../images/brush-icon.inline.svg';
import waveIcon from '../images/wave-icon.inline.svg';
import shieldIcon from '../images/shield-icon.inline.svg';
import cleanIcon from '../images/clean-icon.inline.svg';
import leafIcon from '../images/leaf-icon.inline.svg';
import waveTriangleIcon from '../images/wave-triangle-icon.inline.svg';
import selfAdhesiveIcon from '../images/self-adhesive-icon.inline.svg';
import widthIcon from '../images/width-icon.inline.svg';
import heightIcon from '../images/height-icon.inline.svg';

const StyledProductOptions = styled.div`
  ${minBreakpointQuery.mlarge`
    z-index: ${zIndexLayers.first};
  `}
`;

const StyledOptions = styled.div`
  ${({ addMarginTop }) => {
    if (addMarginTop) {
      return css`
        margin-top: 20px;
        padding-bottom: 5px;
      `;
    }
  }}

  &:not(:first-child) {
    margin-top: 20px;

    ${({ isCroppingTool }) => {
      if (isCroppingTool) {
        return css`
          margin-top: 20px;
        `;
      } else {
        return css`
          margin-top: 16px;
          padding-top: 16px;
          border-top: 1px solid ${standardColours.lightGrey};

          ${minBreakpointQuery.tsmall`
            padding-top: 24px;
          `}
        `;
      }
    }}

    ${minBreakpointQuery.tsmall`
      margin-top: 24px;
    `}
  }
`;

const StyledHeading = styled.span`
  font-weight: ${({ isCroppingTool }) =>
    isCroppingTool ? fontWeights.bold : fontWeights.semibold};
  ${fontSize(14)};
  color: ${({ isCroppingTool }) =>
    isCroppingTool ? standardColours.black : brandColours.primary};

  ${minBreakpointQuery.tsmall`
    ${fontSize(16)};
  `}
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const StyledRadioOptions = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
  margin-top: 14px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  ${({ isPaperType }) => {
    if (isPaperType) {
      return css`
        flex-direction: column;
      `;
    }
  }}
`;

const StyledColourOption = styled.label`
  position: relative;
  cursor: pointer;
  height: 24px;

  ${({ variantColour }) => {
    if (variantColour) {
      return css`
        &:before {
          content: '';
          display: block;
          height: 24px;
          width: 24px;
          background-color: ${({ variantColour }) =>
            variantColour
              ? variantColour
              : standardColours.transparentBlack(0.5)};
          border-radius: 50%;
          border: 1px solid ${standardColours.lightGrey};
        }
      `;
    }
  }}

  ${({ isCustom }) => {
    if (isCustom) {
      return css`
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 24px;
        width: 24px;
        border: 2px solid ${standardColours.darkerGrey};
        border-radius: 50%;
      `;
    }
  }}

  ${({ active }) => {
    if (active) {
      return css`
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          display: block;
          height: 34px;
          width: 34px;
          border-radius: 50%;
          border: 1px solid ${standardColours.grey};
        }
      `;
    }
  }}

  span {
    ${({ isCustom }) => {
      if (!isCustom) {
        return css`
          ${visuallyHidden()}
        `;
      } else {
        return css`
          display: block;
          width: 14px;
          height: 14px;
          text-align: center;
          line-height: 14px;
          ${fontSize(14)}
          color: ${standardColours.darkerGrey};
          font-weight: ${fontWeights.semibold};
        `;
      }
    }}
  }
`;

const StyledWallDimensionsIntro = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ isCroppingTool }) => {
    if (isCroppingTool) {
      return css`
        column-gap: 10px;
      `;
    }
  }}
`;

const StyledButtons = styled.div`
  display: flex;
  background-color: ${standardColours.lighterGrey};
  border-radius: 20px;
  padding: 6px;
`;

const StyledWallDimensionsInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  margin-top: 15px;
`;

const StyledWallDimensionsInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledWallDimensionsInputIcon = styled(Svg)`
  height: 20px;
  width: 20px;
`;

const StyledWallDimensionsInput = styled.input`
  padding: 14px 14px 14px 16px;
  line-height: 1.4;
  border: none;
  width: 100%;
  ${fontSize(12)}
  font-weight: ${fontWeights.medium};
  background-color: ${standardColours.lighterGrey};

  &:focus {
    ${maxBreakpointQuery.smedium`
      ${fontSize(16)}
    `}
  }
`;

const StyledButton = styled.button`
  color: ${standardColours.black};
  border: none;
  ${fontSize(12)}
  font-weight: ${fontWeights.semibold};
  border-radius: 20px;
  padding: 4px 10px;
  width: 38px;
  height: 26px;
  background: none;

  ${({ active }) => {
    if (active) {
      return css`
        background-color: ${standardColours.white};
        box-shadow: 0px 2px 4px 0px ${standardColours.transparentBlack(0.08)};
      `;
    }
  }}
`;

const StyledExcessMessaging = styled.p`
  margin-top: 10px;
  ${fontSize(12)}
  color: ${standardColours.darkerGrey};
`;

const StyledTermsConditions = styled.label`
  margin: 20px 0;
  display: block;
  cursor: pointer;
  position: relative;
`;

// moved up for checked
const StyledCheckboxText = styled.span`
  position: relative;
  display: block;
  padding-left: 30px;
  ${fontSize(12)};
  color: ${standardColours.darkerGrey};
  font-weight: ${fontWeights.medium};
  line-height: 1.6;

  ${minBreakpointQuery.tsmall`
    ${fontSize(14)};
  `}

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
  }

  &:before {
    top: 4px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid ${standardColours.darkerGrey};
  }

  &:after {
    left: 5px;
    top: 10px;
    height: 5px;
    width: 10px;
    border: 2px solid ${standardColours.white};
    border-top: 0;
    border-right: 0;
    opacity: 0;
    transform: rotate(-45deg);
    transition: ${standardTransition('opacity')};
  }
`;

const StyledCheckbox = styled.input`
  ${visuallyHidden()};

  &:checked + ${StyledCheckboxText} {
    &:after {
      opacity: 1;
    }
    &:before {
      background-color: ${standardColours.green};
      border: none;
    }
  }
`;

const StyledPaperTypeOption = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
  ${fontSize(12)};
  padding-left: 24px;
  line-height: 20px;

  ${minBreakpointQuery.tsmall`
    ${fontSize(14)};
  `}

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid ${brandColours.primary};

    ${minBreakpointQuery.tsmall`
      width: 18px;
      height: 18px;
    `}
  }

  &:after {
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ active }) =>
      active ? brandColours.primary : standardColours.white};

    ${minBreakpointQuery.tsmall`
      width: 10px;
      height: 10px;
    `}
  }
`;

const StyledRadio = styled.input`
  ${visuallyHidden()}
`;

const StyledPopular = styled.span`
  display: inline-block;

  ${({ isCroppingTool }) => {
    if (isCroppingTool) {
      return css`
        float: right;
      `;
    }
  }}
`;

const StyledIcon = styled(Svg)`
  margin: 0 5px;
  vertical-align: sub;

  ${({ isCroppingTool }) => {
    if (isCroppingTool) {
      return css`
        width: 16px;
        height: 16px;
      `;
    } else {
      return css`
        width: 14px;
        height: 14px;
      `;
    }
  }}

  polygon {
    fill: #ffc82e;
  }
`;

const StyledPaperTypeInfo = styled.div`
  margin-top: 16px;
  display: flex;
  border: 1px solid ${standardColours.lightGrey};
  border-radius: 8px;
  padding: 12px;
`;

const StyledPaperTypeInfoIcon = styled(Svg)``;

const StyledPaperTypeInfoText = styled.span`
  ${fontSize(11)};
  color: ${standardColours.darkerGrey};
  margin-left: 8px;

  ${minBreakpointQuery.tsmall`
    ${fontSize(12)};
  `}
`;

const StyledPaperTypeOptionCrop = styled.label`
  position: relative;
  cursor: pointer;
  padding: 12px;
  font-weight: ${fontWeights.semibold};
  ${fontSize(14)};

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    left: 12px;
    top: 16px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid ${brandColours.primary};

    ${minBreakpointQuery.tsmall`
      width: 18px;
      height: 18px;
      left: 14px;
      top: 20px;
    `}
  }

  &:after {
    left: 16px;
    top: 20px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ active }) =>
      active ? brandColours.primary : standardColours.white};

    ${minBreakpointQuery.tsmall`
      width: 10px;
      height: 10px;
      left: 18px;
      top: 24px;
    `}
  }

  ${minBreakpointQuery.tsmall`
    ${fontSize(16)};
    padding: 16px;
  `}

  ${({ active }) => {
    if (active) {
      return css`
        border: 2px solid ${standardColours.black};
      `;
    } else {
      return css`
        border: 1px solid ${standardColours.lightGrey};
      `;
    }
  }}
`;

const StyledPaperTypeOptionCropText = styled.span`
  margin-left: 25px;
`;

const StyledRadioCrop = styled.input`
  ${visuallyHidden()}
`;

const StyledPaperTypeFeatures = styled.ul`
  font-weight: ${fontWeights.medium};
  margin-top: 12px;
  ${fontSize(14)};
  display: ${({ display }) => (display ? 'block' : 'none')};

  ${minBreakpointQuery.mlarge`
    margin-top: 16px;
  `}
`;

const StyledPaperTypeFeature = styled.li`
  display: flex;
  column-gap: 8px;
  margin-top: 8px;

  ${minBreakpointQuery.tsmall`
    margin-top: 12px;
  `}
`;

const StyledPaperTypeFeatureIcon = styled(Svg)`
  height: 16px;
  width: 16px;
`;

const ProductOptionsV2 = ({
  options,
  getVariant,
  variants,
  selectedVariant,
  variantPrice,
  handleChange,
  isMural,
  isCustomColour,
  selectedUnit,
  minOrderValues,
  paperTypes,
  cropWidth,
  cropHeight,
  inputFocus,
  croppingMode,
  setTotal,
  setCropWidth,
  setCropHeight,
  setSelectedUnit,
  setInputFocus,
  setIsCustomColour,
  toggleCroppingModeOpen,
  excessMessaging,
  isCroppingTool,
  locale,
}) => {
  const { currency, currencyLoaded } = useContext(StoreContext);

  const [paperTypeIndex, setPaperTypeIndex] = useState(0);
  const [activeItem, setActiveItem] = useState(0);

  const cropWidthInput = useRef();
  const cropHeightInput = useRef();
  const timeout = useRef(null);

  const unitConversion = selectedUnit === 'cm' ? 10 : 1;

  const displayText =
    selectedUnit === 'cm'
      ? translateString('product.perM', locale)
      : translateString('product.perFt', locale);

  const colourOptions = options.find(({ name }) => name === 'Color');
  const paperTypeOptions = options.find(({ name }) => name === 'Paper Type');

  const paperTypeOptionFeatures = [
    [
      {
        icon: brushIcon,
        text: 'Paste-the-wall',
      },
      {
        icon: waveIcon,
        text: 'Smooth satin finish',
      },
      {
        icon: shieldIcon,
        text: 'Good durability',
      },
      {
        icon: cleanIcon,
        text: 'Wipe clean',
      },
      {
        icon: leafIcon,
        text: 'PVC-free',
      },
    ],
    [
      {
        icon: brushIcon,
        text: 'Paste-the-wall',
      },
      {
        icon: waveTriangleIcon,
        text: 'Sand-textured satin finish',
      },
      {
        icon: shieldIcon,
        text: 'Excellent durability',
      },
      {
        icon: cleanIcon,
        text: 'Scrub clean',
      },
    ],
    [
      {
        icon: selfAdhesiveIcon,
        text: 'Self-adhesive',
      },
      {
        icon: waveIcon,
        text: 'Smooth satin finish',
      },
      {
        icon: shieldIcon,
        text: 'Good durability',
      },
      {
        icon: cleanIcon,
        text: 'Wipe clean',
      },
      {
        icon: leafIcon,
        text: 'PVC-free',
      },
    ],
  ];

  // Calculate min order value
  const minOrderQuanity = Math.round(
    minOrderValues[variantPrice.currencyCode] / variantPrice.amount
  );

  const minOrderValue = variantPrice.amount * minOrderQuanity;

  const calculatePrice = (width, height) => {
    // Calculate m2 quanity
    const m2 =
      ((width * (selectedUnit === 'cm' ? 1 : 2.54)) / 100) *
      ((height * (selectedUnit === 'cm' ? 1 : 2.54)) / 100);

    // Min order value check
    width > 0 &&
      height > 0 &&
      setTotal(
        variantPrice.amount * (m2.toFixed(1) * 10) < minOrderValue
          ? minOrderValue
          : variantPrice.amount * (m2.toFixed(1) * 10)
      );
  };

  useEffect(() => {
    calculatePrice(cropWidth, cropHeight);
  }, [selectedUnit, variantPrice]);

  useEffect(() => {
    if (croppingMode) {
      if (inputFocus === 'crop-width') {
        cropWidthInput.current.focus();
      } else {
        cropHeightInput.current.focus();
      }
    }
  }, [croppingMode, inputFocus]);

  return (
    <StyledProductOptions>
      {colourOptions.values.length > 1 && (
        <StyledOptions addMarginTop={isCroppingTool}>
          <StyledHeading isCroppingTool={isCroppingTool}>
            1. {translateString('product.select', locale)}{' '}
            {colourOptions.translations && colourOptions.translations.name
              ? colourOptions.translations.name.toLowerCase()
              : currency !== 'USD'
              ? 'Colour'
              : 'Color'}
            :{' '}
            {isCustomColour ? (
              <StyledLink to="/contact">{`Contact for custom ${
                currency !== 'USD' ? 'colour' : 'color'
              }`}</StyledLink>
            ) : (
              selectedVariant.selectedOptions.find(
                selectedOption => selectedOption.name === 'Color'
              ).value
            )}
          </StyledHeading>
          <StyledRadioOptions>
            {colourOptions.values.map((value, id) => (
              <StyledColourOption
                key={id}
                active={
                  !isCustomColour &&
                  selectedVariant.selectedOptions.find(
                    selectedOption => selectedOption.name === 'Color'
                  ).value === value
                }
                variantColour={getColourHex(
                  variants.find(
                    variant =>
                      variant.selectedOptions.find(
                        option =>
                          option.name === 'Color' && option.value === value
                      )?.value
                  ).metafields
                )}
              >
                <StyledRadio
                  type="radio"
                  name="Color"
                  value={value}
                  onChange={e => {
                    setIsCustomColour(false);
                    handleChange(
                      'Color',
                      e.currentTarget.value,
                      options.findIndex(({ name }) => name === 'Color')
                    );
                  }}
                />
                <span>
                  {colourOptions.translations
                    ? colourOptions.translations.values[id]
                    : value}
                </span>
              </StyledColourOption>
            ))}
            <StyledColourOption active={isCustomColour} isCustom={true}>
              <StyledRadio
                type="radio"
                name="Color"
                value="custom"
                onChange={() => setIsCustomColour(!isCustomColour)}
              />
              <span>?</span>
            </StyledColourOption>
          </StyledRadioOptions>
        </StyledOptions>
      )}
      {isMural && (
        <StyledOptions
          addPaddingTop={
            colourOptions.values.length > 1 ? false : isCroppingTool
          }
          isCroppingTool={isCroppingTool}
        >
          <StyledWallDimensionsIntro
            id="wall-dimensions"
            isCroppingTool={isCroppingTool}
          >
            <StyledHeading isCroppingTool={isCroppingTool}>
              {colourOptions.values.length > 1 ? '2.' : '1.'}{' '}
              {translateString('croppingTool.enterDimensions', locale)}
            </StyledHeading>
            <StyledButtons>
              <StyledButton
                active={selectedUnit === 'cm'}
                onClick={e => {
                  e.preventDefault();
                  setSelectedUnit('cm');
                }}
              >
                <span>cm</span>
              </StyledButton>
              {locale !== 'fr' && (
                <StyledButton
                  active={selectedUnit === 'in'}
                  onClick={e => {
                    e.preventDefault();
                    setSelectedUnit('in');
                  }}
                >
                  <span>in</span>
                </StyledButton>
              )}
            </StyledButtons>
          </StyledWallDimensionsIntro>
          <StyledWallDimensionsInputs>
            <StyledWallDimensionsInputWrapper>
              <StyledWallDimensionsInputIcon image={widthIcon} />
              <StyledWallDimensionsInput
                name="crop-width"
                ref={cropWidthInput}
                type="number"
                placeholder={`${translateString(
                  'croppingTool.widthPlaceholder',
                  locale
                )} ${selectedUnit ? `(${selectedUnit})` : ''}`}
                required={true}
                defaultValue={cropWidth}
                onFocus={() => {
                  toggleCroppingModeOpen && toggleCroppingModeOpen();
                  setInputFocus && setInputFocus('crop-width');
                }}
                onChange={e => {
                  clearTimeout(timeout.current);
                  timeout.current = setTimeout(() => {
                    e.target.value > 10 && setCropWidth(e.target.value);
                  }, 200);
                  calculatePrice(e.target.value, cropHeight);
                }}
              />
            </StyledWallDimensionsInputWrapper>
            <StyledWallDimensionsInputWrapper>
              <StyledWallDimensionsInputIcon image={heightIcon} />
              <StyledWallDimensionsInput
                name="crop-height"
                ref={cropHeightInput}
                type="number"
                placeholder={`${translateString(
                  'croppingTool.heightPlaceholder',
                  locale
                )} ${selectedUnit ? `(${selectedUnit})` : ''}`}
                required={true}
                defaultValue={cropHeight}
                onFocus={() => {
                  toggleCroppingModeOpen && toggleCroppingModeOpen();
                  setInputFocus && setInputFocus('crop-height');
                }}
                onChange={e => {
                  clearTimeout(timeout.current);
                  timeout.current = setTimeout(() => {
                    e.target.value > 10 && setCropHeight(e.target.value);
                  }, 200);
                  calculatePrice(cropWidth, e.target.value);
                }}
              />
            </StyledWallDimensionsInputWrapper>
          </StyledWallDimensionsInputs>
          {!isCroppingTool ? (
            <StyledExcessMessaging isCroppingTool={isCroppingTool}>
              {excessMessaging}
            </StyledExcessMessaging>
          ) : (
            <StyledTermsConditions>
              <StyledCheckbox
                id="terms-conditions-optin"
                name="terms-conditions-optin"
                type="checkbox"
                required={true}
              />
              <StyledCheckboxText>
                {translateString('croppingTool.terms', locale)}
              </StyledCheckboxText>
            </StyledTermsConditions>
          )}
        </StyledOptions>
      )}
      <StyledOptions isCroppingTool={isCroppingTool}>
        <StyledHeading isCroppingTool={isCroppingTool}>
          {colourOptions.values.length > 1
            ? isMural
              ? '3.'
              : '2.'
            : isMural
            ? '2.'
            : '1.'}{' '}
          {translateString('product.select', locale)}{' '}
          {paperTypeOptions.translations && paperTypeOptions.translations.name
            ? paperTypeOptions.translations.name.toLowerCase()
            : paperTypeOptions.name.toLowerCase()}
        </StyledHeading>
        <StyledRadioOptions isPaperType={true}>
          {paperTypeOptions.values.map((value, id) => {
            const variantOptions = {
              ...selectedVariant.selectedOptions,
              [options.findIndex(option => option.name === 'Paper Type')]: {
                name: 'Paper Type',
                value,
              },
            };

            const variant = getVariant(variants, variantOptions);

            if (!variant) {
              return;
            }

            const variantPrice = getVariantPrice(variant);

            return !isCroppingTool ? (
              <StyledPaperTypeOption
                key={id}
                active={
                  selectedVariant.selectedOptions.find(
                    selectedOption => selectedOption.name === 'Paper Type'
                  ).value === value
                }
                onChange={() => setPaperTypeIndex(id)}
              >
                <StyledRadio
                  type="radio"
                  name="WP-Paper Type"
                  value={value}
                  onChange={e =>
                    handleChange(
                      'Paper Type',
                      e.currentTarget.value,
                      options.findIndex(({ name }) => name === 'Paper Type')
                    )
                  }
                />
                <span>
                  {paperTypeOptions.translations
                    ? paperTypeOptions.translations.values[id]
                    : value === 'Self Adhesive'
                    ? 'Peel & Stick'
                    : value}
                  :{' '}
                  {currencyLoaded &&
                    formatPrice({
                      amount:
                        variantPrice.amount * (isMural ? unitConversion : 1),
                      currencyCode: variantPrice.currencyCode,
                      locale: locale,
                    })}{' '}
                  {isMural
                    ? displayText
                    : translateString('product.perRoll', locale)}
                  {isMural && <sup>2</sup>}{' '}
                  {value === 'Luxury' && (
                    <StyledPopular>
                      {' - '}
                      <StyledIcon image={starIcon} />
                      {translateString('product.popular', locale)}
                    </StyledPopular>
                  )}
                </span>
              </StyledPaperTypeOption>
            ) : (
              <StyledPaperTypeOptionCrop
                key={id}
                active={
                  selectedVariant.selectedOptions.find(
                    selectedOption => selectedOption.name === 'Paper Type'
                  ).value === value
                }
                onChange={() => setPaperTypeIndex(id)}
              >
                <StyledRadioCrop
                  type="radio"
                  name="WP-Paper Type"
                  value={value}
                  onChange={e => {
                    handleChange(
                      'Paper Type',
                      e.currentTarget.value,
                      options.findIndex(({ name }) => name === 'Paper Type')
                    );

                    setActiveItem(id);
                  }}
                />
                <StyledPaperTypeOptionCropText>
                  {paperTypeOptions.translations
                    ? paperTypeOptions.translations.values[id]
                    : value === 'Self Adhesive'
                    ? 'Peel & Stick'
                    : value}
                  :{' '}
                  {currencyLoaded &&
                    formatPrice({
                      amount:
                        variantPrice.amount * (isMural ? unitConversion : 1),
                      currencyCode: variantPrice.currencyCode,
                      locale: locale,
                    })}{' '}
                  {isMural
                    ? displayText
                    : translateString('product.perRoll', locale)}
                  {isMural && <sup>2</sup>}{' '}
                  {value === 'Luxury' && (
                    <StyledPopular isCroppingTool={isCroppingTool}>
                      <StyledIcon
                        image={starIcon}
                        isCroppingTool={isCroppingTool}
                      />
                      {translateString('product.popular', locale).replace(
                        'p',
                        'P'
                      )}
                    </StyledPopular>
                  )}
                </StyledPaperTypeOptionCropText>
                {paperTypeOptionFeatures[id] && (
                  <StyledPaperTypeFeatures display={activeItem === id}>
                    {paperTypeOptionFeatures[id].map(({ icon, text }, id) => (
                      <StyledPaperTypeFeature key={id}>
                        <StyledPaperTypeFeatureIcon image={icon} />
                        <span>{text}</span>
                      </StyledPaperTypeFeature>
                    ))}
                  </StyledPaperTypeFeatures>
                )}
              </StyledPaperTypeOptionCrop>
            );
          })}
        </StyledRadioOptions>
        {!isCroppingTool && (
          <StyledPaperTypeInfo>
            <StyledPaperTypeInfoIcon image={infoIcon} />
            <StyledPaperTypeInfoText>
              {
                paperTypes.find(({ name }) =>
                  paperTypeOptions.translations
                    ? name ===
                      paperTypeOptions.translations.values[paperTypeIndex]
                    : name === paperTypeOptions.values[paperTypeIndex]
                )?.shortText
              }
            </StyledPaperTypeInfoText>
          </StyledPaperTypeInfo>
        )}
      </StyledOptions>
    </StyledProductOptions>
  );
};

export default ProductOptionsV2;
