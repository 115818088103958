import React, { useRef, useEffect, useState, useContext } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { Container, Button, HtmlContent } from './ui';
import {
  brandColours,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  standardColours,
  visuallyHidden,
  maxBreakpointQuery,
} from '../styles';
import { useVariantPrice, formatPrice, translateString } from '../utils';
import { StoreContext } from '../context/StoreContext';
import PopOutSidebar, { StyledContent } from './PopOutSidebar';
import ProductOptionsV2 from './ProductOptionsV2';
import DispatchNotice from './DispatchNotice';
import moveCursor from '../images/cursor-move.svg';
import moveCursorWideCrop from '../images/cursor-move-wide-crop.svg';

const StyledCroppingTool = styled(PopOutSidebar)`
  right: 0;
  max-width: 100%;
  overflow-x: hidden;

  ${minBreakpointQuery.mlarge`
    overflow: hidden;
  `}
`;

const StyledContainer = styled(Container)`
  padding: 0;
  max-width: 100%;
`;

const StyledWrapper = styled.div`
  ${minBreakpointQuery.mlarge`
    display: grid;
    grid-template-columns: 350px calc(100% - 380px);
    column-gap: 30px;
    height: 100vh;
  `}
`;

const StyledCropArea = styled.div`
  .cropper-container {
    background-color: ${standardColours.lighterGrey};
  }

  .cropper-view-box {
    outline: none;
  }

  .cropper-canvas {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.65;
      background-color: ${standardColours.black};
    }
  }

  .cropper-modal {
    opacity: 0;
  }

  .cropper-move {
    ${({ enableWideCropCursor }) => {
      if (enableWideCropCursor) {
        return css`
          cursor: url(${moveCursorWideCrop}), move;
        `;
      } else {
        return css`
          cursor: url(${moveCursor}), move;
        `;
      }
    }}
  }

  .cropper-face {
    background: none;
  }
`;

const StyledClose = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  padding-top: 24px;
`;

const StyledBackButton = styled.button`
  background: none;
  border: 1px solid ${standardColours.lightGrey};
  padding: 10px 12px;

  span {
    position: relative;
    padding-left: 14px;
    font-weight: ${fontWeights.medium};
    ${fontSize(14)};

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      height: 8px;
      width: 8px;
      border: 2px solid ${standardColours.black};
      border-top: 0;
      border-right: 0;
      transform: translateY(-50%) rotate(45deg);
    }
  }
`;

const StyledCloseButton = styled.button`
  height: 42px;
  width: 42px;
  background: none;
  border: 1px solid ${standardColours.lightGrey};

  &:before {
    content: '✕';
    font-weight: ${fontWeights.bold};
    ${fontSize(16)};
  }

  span {
    ${visuallyHidden()};
  }
`;

const StyledHeading = styled.h3`
  ${fontSize(26)}
  font-weight: ${fontWeights.bold};
  color: ${standardColours.black};
  line-height: 1.2;
  margin-bottom: 16px;

  ${minBreakpointQuery.small`
    margin-bottom: 20px;
    ${fontSize(28)}
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 24px;
    ${fontSize(30)}
  `}

  ${({ isCropArea }) => {
    if (isCropArea) {
      return css`
        ${minBreakpointQuery.mlarge`
          display: none;
        `}
      `;
    } else {
      return css`
        ${maxBreakpointQuery.mlarge`
          display: none;
        `}
      `;
    }
  }}
`;

const StyledCropperWrapper = styled.div`
  background-color: ${standardColours.lighterGrey};
  display: flex;
  align-items: center;

  ${minBreakpointQuery.mlarge`
    height: calc(100vh - ${({ enableWideCrop }) =>
      enableWideCrop ? '320' : '220'}px);
  `}

  ${({ enableWideCrop }) => {
    if (enableWideCrop) {
      return css`
        min-height: 300px;
      `;
    }
  }}
`;

const StyledCanvasWrapper = styled.div`
  max-height: 100%;
  margin: 0 auto;

  .cropper-container {
    &::after {
      content: '';
      display: ${({ cropHoverActive }) => (cropHoverActive ? 'none' : 'block')};
      position: absolute;
      top: 50%;
      left: 50%;
      width: 28px;
      height: 28px;
      transform: translate(-50%, -50%);

      ${({ enableWideCropCursor }) => {
        if (enableWideCropCursor) {
          return css`
            background-image: url(${moveCursorWideCrop});
          `;
        } else {
          return css`
            background-image: url(${moveCursor});
          `;
        }
      }}

      ${({ cropScale }) => {
        if (cropScale) {
          return css`
            transform: translate(-50%, -50%) scale(calc(1 / ${cropScale}));
          `;
        }
      }}
    }
  }

  ${({ aspectRatio }) => {
    if (aspectRatio) {
      return css`
        aspect-ratio: ${aspectRatio};
        overflow: hidden;
      `;
    }
  }}

  ${({ isPortrait }) => {
    if (isPortrait) {
      return css`
        height: 100%;
      `;
    }
  }}

  ${({ enableWideCrop, isPortrait }) => {
    if (!enableWideCrop && !isPortrait) {
      return css`
        max-width: 100%;
      `;
    }
  }}
`;

const StyledCanvas = styled.canvas`
  display: block;
  max-width: 100%;
`;

const StyledPreview = styled.div`
  display: none;

  ${minBreakpointQuery.mlarge`
    display: ${({ display }) => (display ? 'block' : 'none')};
    max-width: 430px;
    margin: 16px auto;
  `}
`;

const StyledPreviewWrapper = styled.div`
  .cropper-view-box {
    border: 2px solid ${brandColours.quinary};
  }
`;

const StyledPreviewCanvas = styled.canvas`
  display: block;
  max-width: 100%;
`;

const StyledContentArea = styled(HtmlContent)`
  margin: 20px 0;
  padding: 20px;
  background-color: ${brandColours.senary};

  ${({ isSidebar }) => {
    if (!isSidebar) {
      return css`
        ${minBreakpointQuery.tsmall`
          text-align: center;
        `}
      `;
    }
  }}

  p {
    ${fontSize(12)}
    margin-bottom: 8px;

    ${minBreakpointQuery.tsmall`
      ${fontSize(14)}
    `}

    strong {
      ${fontSize(16)}

      ${minBreakpointQuery.tsmall`
        ${fontSize(18)}
      `}
    }
  }

  ul {
    margin-bottom: 8px;

    li {
      ${fontSize(12)}

      ${minBreakpointQuery.tsmall`
        ${fontSize(14)}
      `}

      &::before {
        height: 1px;
      }
    }
  }
`;

const StyledInner = styled.form`
  ${maxBreakpointQuery.mlarge`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-column: 1;
    grid-row: 1 / 3;
    padding-right: 20px;
    padding-top: 24px;
    border-right: 1px solid ${standardColours.lightGrey};
    overflow-y: scroll;
  `}
`;

const StyledValidation = styled.div`
  margin: 20px 0;
  border: 1px solid ${standardColours.darkGrey};
  padding: 16px;
  border-radius: 5px;
  background-color: ${({ validationMessage }) =>
    validationMessage === 'allow' ? '#fedfc9' : '#ff6961'};
`;

const StyledValidationText = styled.p`
  ${fontSize(12)}
`;

const StyledTotalAdd = styled.div`
  position: sticky;
  bottom: 0;
  padding-top: 5px;
  padding-bottom: 15px;
  margin-top: 25px;
  background-color: ${standardColours.white};

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: calc(100% + 40px);
    top: 0;
    left: -20px;
    height: 100%;
    z-index: -1;
    box-shadow: 0px -6px 14px 0px rgba(0, 0, 0, 0.08);
    background-color: ${standardColours.white};
  }
`;

const StyledTotal = styled.p`
  font-weight: ${fontWeights.bold};
  ${fontSize(20)}
  margin: 12px 0;

  span {
    margin-left: 8px;
    color: ${standardColours.darkerGrey};
    ${fontSize(12)}
    font-weight: ${fontWeights.regular};
  }
`;

const StyledDispatchNotice = styled(DispatchNotice)`
  margin-bottom: 16px;
  margin-top: 0;
`;

const StyledAddButton = styled(Button)`
  width: 100%;
  background-color: ${brandColours.primary};
  border-color: ${brandColours.primary};
`;

const CroppingTool = ({
  image,
  croppingMode,
  toggleCroppingModeOpen,
  options,
  handleChange,
  variants,
  getVariant,
  selectedVariant,
  selectedUnit,
  cropWidth,
  cropHeight,
  inputFocus,
  minOrderValues,
  paperTypes,
  total,
  setTotal,
  setCropWidth,
  setCropHeight,
  setSelectedUnit,
  datoCmsCroppingTool: {
    contentBlock1,
    contentBlockStandardCrop,
    contentBlockWideCrop,
  },
  maxCropImages,
  enableWideCrop,
  locale,
  excessMessaging,
}) => {
  const {
    addProductsToCheckout,
    currency,
    currencyLoaded,
    showHubspotLiveChat,
    setShowHubspotLiveChat,
  } = useContext(StoreContext);

  const variantPrice = useVariantPrice(selectedVariant);

  const [isCustomColour, setIsCustomColour] = useState(false);
  const [cropHoverActive, setCropHoverActive] = useState(false);

  const cropperRef = useRef();
  const previewRef = useRef();
  const [cropperInstance, setCropperInstance] = useState();
  const [previewInstance, setPreviewInstance] = useState();
  const [numberOfCropImages, setNumberOfCropImages] = useState(1);
  const [cropScale, setCropScale] = useState();
  const [aspectRatio, setAspectRatio] = useState();

  const [validationMessage, setValidationMessage] = useState();
  const [readValidation, setReadValidation] = useState(false);

  // Calculate min order value
  const minOrderQuanity = Math.round(
    minOrderValues[variantPrice.currencyCode] / variantPrice.amount
  );

  const minOrderValue = variantPrice.amount * minOrderQuanity;

  const calculatePrice = (width, height) => {
    // Calculate m2 quanity
    const m2 =
      ((width * (selectedUnit === 'cm' ? 1 : 2.54)) / 100) *
      ((height * (selectedUnit === 'cm' ? 1 : 2.54)) / 100);

    // if validation is active remove if passing
    if (validationMessage && m2 >= 1) {
      setValidationMessage();
    }

    // Min order value check
    setTotal(
      variantPrice.amount * (m2.toFixed(1) * 10) < minOrderValue
        ? minOrderValue
        : variantPrice.amount * (m2.toFixed(1) * 10)
    );
  };

  const convertToMM = value => {
    if (selectedUnit === 'cm') {
      return value * 10;
    } else {
      return value * 25.4;
    }
  };

  const unitConversion = selectedUnit
    ? selectedUnit === 'cm'
      ? 10
      : 1
    : undefined;

  const handleSubmit = async e => {
    e.preventDefault();

    let cropData;

    const imageElement = cropperRef.current;
    const previewElement = previewRef.current;

    if (numberOfCropImages > 1) {
      // Convert crop data from preview
      const previewWidth = previewElement.cropBoxData.width;
      const cropboxWidth = imageElement.cropBoxData.width;

      const conversion = cropboxWidth / previewWidth;

      cropData = {
        ...imageElement.cropBoxData,
        left: previewElement.cropBoxData.left * conversion,
        top: 0,
      };
    } else {
      cropData = imageElement.cropBoxData;
    }

    const cropLeft = cropData.left;
    const cropTop = cropData.top;
    const cropAreaWidth = cropData.width;
    const cropAreaHeight = cropData.height;

    const m2 =
      ((cropWidth * (selectedUnit === 'cm' ? 1 : 2.54)) / 100) *
      ((cropHeight * (selectedUnit === 'cm' ? 1 : 2.54)) / 100);

    if (
      variantPrice.amount * (m2.toFixed(1) * 10) < minOrderValue &&
      !readValidation
    ) {
      setValidationMessage({
        message: `These wall dimensions are smaller than our minimum charge. You can still order, but you will be charged ${formatPrice(
          {
            amount: total,
            currencyCode: variantPrice.currencyCode,
          }
        )}`,
        type: 'allow',
      });

      setReadValidation(true);
      setTimeout(() => {
        document
          .getElementById('wall-dimensions')
          .scrollIntoView({ behavior: 'smooth' });
      }, 100);
      return;
    } else {
      setValidationMessage();
    }

    // Convert wall size to mm

    const wallWidthMM = convertToMM(cropWidth);
    const wallHeightMM = convertToMM(cropHeight);

    // px to mm conversion rate

    const conversionRate =
      cropLeft === 0
        ? cropAreaHeight / wallHeightMM
        : cropAreaWidth / wallWidthMM;

    const offset = {
      type: cropLeft === 0 ? 'top' : 'left',
      amount:
        cropLeft === 0 ? cropTop / conversionRate : cropLeft / conversionRate,
      widthMM: wallWidthMM,
      heightMM: wallHeightMM,
      images: numberOfCropImages,
    };

    const lineItems = [
      {
        variantId: selectedVariant.storefrontId,
        quantity:
          m2.toFixed(1) * 10 < minOrderQuanity
            ? minOrderQuanity
            : m2.toFixed(1) * 10,
        customAttributes: [
          { key: 'Width', value: cropWidth },
          { key: 'Height', value: cropHeight },
          { key: 'Unit', value: selectedUnit },
          {
            key: 'Crop data',
            value: JSON.stringify(offset),
          },
        ],
      },
    ];

    await addProductsToCheckout(lineItems);

    toggleCroppingModeOpen();
    setReadValidation(false);
  };

  const rebuildCropper = (cropImage, cropImages) => {
    setNumberOfCropImages(cropImages);
    cropperInstance && cropperInstance.destroy();
    previewInstance && previewInstance.destroy();
    cropImage.src = image.url;
    cropImage.crossOrigin = 'anonymous';
  };

  const transformCropValues = (value, previewCenter, cropboxMaxLeft) => {
    // Given points
    const x1 = 0,
      y1 = previewCenter;
    const x2 = cropboxMaxLeft,
      y2 = 0;

    // Calculate the slope (m)
    const m = (y2 - y1) / (x2 - x1);

    // Calculate the y-intercept (b)
    const b = y1 - m * x1;

    // Return the transformed value using the equation y = mx + b
    return m * value + b;
  };

  const cropperOptions = {
    guides: false,
    dragMode: 'move',
    toggleDragModeOnDblclick: false,
    viewMode: enableWideCrop ? 1 : 3,
    background: false,
    rotatable: false,
    scalable: false,
    movable: enableWideCrop ? true : false,
    zoomable: false,
    zoomOnTouch: false,
    zoomOnWheel: false,
    cropBoxResizable: false,
    cropBoxMovable: enableWideCrop ? false : true,
    center: false,
    initialAspectRatio: 100 / 100,
    aspectRatio: cropWidth / cropHeight,
    autoCropArea: 1,
    restore: false,
    minContainerHeight: 0,
  };

  useEffect(() => {
    const cropImage = new Image();
    let cropImages = numberOfCropImages;

    cropImage.addEventListener('load', () => {
      let cropper;
      let previewCropper;
      const canvas = document.getElementById('canvas');
      const preview = document.getElementById('preview');

      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);

      const width = cropImage.naturalWidth;
      const height = cropImage.naturalHeight;

      canvas.width = width * cropImages;
      canvas.height = height;

      if (cropImages > 1) {
        preview.getContext('2d').clearRect(0, 0, preview.width, preview.height);
        preview.width = width * cropImages;
        preview.height = height;
      }

      // Add each additional image
      for (let index = 0; index < cropImages; index++) {
        canvas
          .getContext('2d')
          .drawImage(
            cropImage,
            width * (index === 0 ? 0 : index),
            0,
            width,
            height
          );

        cropImages > 1 &&
          preview
            .getContext('2d')
            .drawImage(
              cropImage,
              width * (index === 0 ? 0 : index),
              0,
              width,
              height
            );
      }

      enableWideCrop &&
        canvas.addEventListener('crop', () => {
          // Crop container width / crop box width
          // Only run this on desktop for now

          if (cropWidth && cropHeight && cropImages > 1) {
            const containerWidth = cropperRef.current.containerData.width;
            const cropBoxWidth = cropperRef.current.cropBoxData.width;

            cropperRef.current.cropper.style.transform = `scale(${
              containerWidth / cropBoxWidth
            })`;
            setCropScale(containerWidth / cropBoxWidth);

            // Set preview cropbox position
            if (previewRef.current && previewRef.current.cropBoxData) {
              const previewPosition = transformCropValues(
                cropperRef.current.canvasData.left,
                previewRef.current.cropBoxData.maxLeft / 2,
                cropperRef.current.canvasData.maxLeft
              );

              previewRef.current.setCropBoxData({
                ...previewRef.current.cropBoxData,
                left: previewPosition,
              });
            }
          } else {
            if (
              cropperRef.current.cropper &&
              cropperRef.current.cropper.style
            ) {
              cropperRef.current.cropper.style.transform = `scale(1)`;
            }
            setCropScale(1);
          }
        });

      cropper = new Cropper(canvas, {
        ...cropperOptions,
        viewMode: enableWideCrop ? 1 : 3,
        movable: cropImages > 1 ? true : false,
        cropBoxMovable: cropImages > 1 ? false : true,
        ready() {
          setCropperInstance(this.cropper);
        },
      });

      if (cropImages > 1) {
        previewCropper = new Cropper(preview, {
          ...cropperOptions,
          viewMode: 3,
          movable: false,
          cropBoxMovable: false,
          ready() {
            setPreviewInstance(this.cropper);
          },
        });
        previewRef.current = previewCropper;
      }

      cropperRef.current = cropper;
    });

    window.clarity && window.clarity('stop');

    // initial load
    if (!cropWidth && !cropHeight) {
      rebuildCropper(cropImage, cropImages);
      setAspectRatio(image.width / image.height);
    } else if (cropWidth > 10 && cropHeight > 10 && croppingMode) {
      // We need to do more
      if (!enableWideCrop) {
        rebuildCropper(cropImage, cropImages);
      } else {
        const ratioCalc = image.width / image.height;

        // -0.01 used to kick the images up just before it needs to be done by around 1cm

        cropImages = !enableWideCrop
          ? 1
          : Math.floor(cropWidth / cropHeight / (ratioCalc - 0.01)) + 1 <=
            (maxCropImages ? maxCropImages : 3)
          ? Math.floor(cropWidth / cropHeight / (ratioCalc - 0.01)) + 1
          : maxCropImages
          ? maxCropImages
          : 3;

        // If the number is not odd then add one
        if (!(cropImages % 2) && enableWideCrop) {
          cropImages = cropImages + 1;
        }

        // See if we need to rebuild the cropper
        rebuildCropper(cropImage, cropImages);
      }
      setAspectRatio(
        cropImages > 1 ? cropWidth / cropHeight : image.width / image.height
      );
    }
  }, [cropWidth, cropHeight, image, enableWideCrop, croppingMode]);

  useEffect(() => {
    calculatePrice(cropWidth, cropHeight);
  }, [selectedUnit, variantPrice, cropWidth, cropHeight]);

  useEffect(() => {
    setSelectedUnit(selectedUnit);
  }, [selectedUnit]);

  // Remove Hubspot chat
  useEffect(() => {
    croppingMode && setShowHubspotLiveChat(false);
  }, [croppingMode, showHubspotLiveChat]);

  return (
    <StyledCroppingTool
      isOpen={croppingMode}
      removeHeader={true}
      enableOverflow={true}
    >
      <StyledContent enableOverflow={true} isCroppingTool={true}>
        <StyledContainer>
          <StyledWrapper>
            <StyledCropArea enableWideCropCursor={enableWideCrop}>
              <StyledClose>
                <StyledBackButton onClick={toggleCroppingModeOpen}>
                  <span>{translateString('sidebar.back', locale)}</span>
                </StyledBackButton>
                <StyledCloseButton onClick={toggleCroppingModeOpen}>
                  <span>Close</span>
                </StyledCloseButton>
              </StyledClose>
              <StyledHeading isCropArea={true}>
                {translateString('croppingTool.heading', locale)}
              </StyledHeading>
              <StyledCropperWrapper
                enableWideCrop={enableWideCrop && numberOfCropImages > 1}
              >
                <StyledCanvasWrapper
                  aspectRatio={aspectRatio && aspectRatio}
                  enableWideCropCursor={enableWideCrop}
                  enableWideCrop={enableWideCrop && numberOfCropImages > 1}
                  cropHoverActive={cropHoverActive}
                  cropScale={cropScale}
                  isPortrait={image.width < image.height}
                  onMouseEnter={() => setCropHoverActive(true)}
                  onTouchStart={() => setCropHoverActive(true)}
                >
                  <StyledCanvas id="canvas"></StyledCanvas>
                </StyledCanvasWrapper>
              </StyledCropperWrapper>
              <StyledPreview display={numberOfCropImages > 1}>
                <StyledPreviewWrapper>
                  <StyledPreviewCanvas id="preview"></StyledPreviewCanvas>
                </StyledPreviewWrapper>
              </StyledPreview>
              <StyledContentArea
                html={
                  enableWideCrop
                    ? contentBlockWideCrop
                    : contentBlockStandardCrop
                }
              />
            </StyledCropArea>
            <StyledInner onSubmit={e => handleSubmit(e)}>
              <StyledHeading>
                {translateString('croppingTool.heading', locale)}
              </StyledHeading>
              {validationMessage && validationMessage.message && (
                <StyledValidation
                  validationMessage={
                    validationMessage && validationMessage.type
                  }
                >
                  <StyledValidationText>
                    {validationMessage.message}
                  </StyledValidationText>
                </StyledValidation>
              )}
              <ProductOptionsV2
                options={options}
                getVariant={getVariant}
                variants={variants}
                variantPrice={variantPrice}
                selectedVariant={selectedVariant}
                handleChange={handleChange}
                isMural={true}
                isCustomColour={isCustomColour}
                selectedUnit={selectedUnit}
                minOrderValues={minOrderValues}
                paperTypes={paperTypes}
                cropWidth={cropWidth}
                cropHeight={cropHeight}
                inputFocus={inputFocus}
                croppingMode={croppingMode}
                setTotal={setTotal}
                setCropWidth={setCropWidth}
                setCropHeight={setCropHeight}
                setSelectedUnit={setSelectedUnit}
                setIsCustomColour={setIsCustomColour}
                excessMessaging={excessMessaging}
                isCroppingTool={true}
                locale={locale}
              />
              {contentBlock1 && (
                <StyledContentArea html={contentBlock1} isSidebar={true} />
              )}
              <StyledTotalAdd>
                <StyledTotal>
                  {variantPrice &&
                    currencyLoaded &&
                    unitConversion &&
                    formatPrice({
                      amount: total
                        ? total
                        : variantPrice.amount * unitConversion,
                      currencyCode: variantPrice.currencyCode,
                      locale: locale,
                    })}
                  <span>{translateString('product.taxNote', locale)}</span>
                </StyledTotal>
                <StyledDispatchNotice
                  total={total}
                  currency={currency}
                  locale={locale}
                />
                {isCustomColour ? (
                  <StyledAddButton
                    onClick={e => {
                      e.preventDefault();
                      navigate('/contact/');
                    }}
                  >
                    {translateString('product.contact', locale)}
                  </StyledAddButton>
                ) : (
                  <StyledAddButton>
                    {translateString('product.addToCart', locale)}
                  </StyledAddButton>
                )}
              </StyledTotalAdd>
            </StyledInner>
          </StyledWrapper>
        </StyledContainer>
      </StyledContent>
    </StyledCroppingTool>
  );
};

export default CroppingTool;
